import { useBreakpoints } from '@/hooks/useBreakpoints'
import classNames from 'classnames'

const Tags = ({ tags }: any) => {
  const { isDesktop } = useBreakpoints()
  const tagBoxClass = classNames(
    'text-blue-500 transition duration-150 border-2 border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white px-4 py-2 font-heading',
    { 'text-sm': !isDesktop },
    { 'text-heading-6': isDesktop }
  )
  return (
    <div className="flex space-x-4">
      {tags.map((tag: any, index: number) => (
        <div key={index} className={tagBoxClass}>
          #{tag.name}
        </div>
      ))}
    </div>
  )
}

export default Tags
